import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track11LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">LOOPS</p>
				<p id="lyrics-text">Time ticking slowly<br></br>
					I been saying now<br></br>
					Im just wishing all these moments can stay<br></br>
					I been cleaning up the nose bleeds<br></br>
					Yeah Im down and out<br></br>
					I tried to face my fears today<br></br>
					Time tick tattle tole toil Titch<br></br>
					Tell me what’s the magic word, to turn back time, I need a trip<br></br>
					I can fix all my mistakes, just give me chance to bargain it<br></br>
					feel like every thing now matters I cannot fall or slip<br></br>
					<br></br>
					just stare and watch the time wiping lines on your face<br></br>
					Said my life is out of balance and I keep pushing on the gauge<br></br>
					And all my thoughts are raining now is it pattern or parade<br></br>
					cause ive been trying to find figure nines that I can align with the snake<br></br>
					Seeeee I been stalling now, thinking to myself, am I everything that I can be<br></br>
					Are my dreams real or delusion, am I enough for my friends and my family<br></br>
					This is the middle of everything I was and everything ill ever be<br></br>
					Judge me or love me, hug me or cut me I just need you to see <br></br>
					<br></br>
					Stop looking at me<br></br>
					Your eyes they burn right through my face<br></br>
					Stop looking at me<br></br>
					I won't show my feelings today<br></br>
					<br></br>
					Sick of you seeing me at my worst, this my house made of fears<br></br>
					Sick of my dreams feeling draining can I ask am I farther or near<br></br>
					Im sick of feeling like im running out of time to be who I wanted<br></br>
					Im sick of feeling like im falling short of myself am I starlight or comet<br></br>
					Can you make me feel free, what is it that I am waiting on to complete me<br></br>
					Why on most days most hours most minutes Ive been feeling uneasy<br></br>
					Ive been nervous ive been anxious now am I burdened and am I blameless<br></br>
					I keep dying I keep reviving this my loop and this my payment<br></br>
					Sugar on my teeth from the bees tell me if you wont notice<br></br>
					This umbrella catch all the raindrops that been falling I know this<br></br>
					Every second every hour that ive been chasing my focus<br></br>
					Yeah I been fighting for my control my peace is all I hold closest<br></br>
					<br></br>
					Would you rather believe me or tell me or something<br></br>
					Could I ever become more than what I am right now?<br></br>
					Is my hard work enough am I doing enough am I growing impatient I need you to back the fuck up off me<br></br>
					i need some time and my space<br></br>
					i need you to remind me of everything i did to get HERE<br></br>
					no I said I am not a fluke<br></br>
					<br></br>
					Fishing for lead in my head am I cattle for sale or to keep, I can’t clue it<br></br>
					Look inside of my eyes and see the culmination of everything im made of and I ruined<br></br>
					Every moment inside these songs that I cherish because for a second im proven<br></br>
					Every minute I spend with you to share myself, this is all that I have in my blueprint<br></br>
					Time tick tattle tale, tell me what’s the case for infinity<br></br>
					Everybody dies eventually so just tell me how could you ever injure me<br></br>
					I just want to run away say ok fuck everything can I stop caring so much please<br></br>
					Look inside my glass skull please do break in case of emergency, ya-i'll be free<br></br>
					Ya-I'll be ok, ya-I'll be everything that im not<br></br>
					All I see are UFOs descending down the clouds from tree tops<br></br>
					My nose is bleeding see the right brain can’t handle time hops<br></br>
					My entourage of feelings bubble up to compromise, the mine pops<br></br>
				</p>
			</main>
		);
	}
}

export default Track11LyricsView;