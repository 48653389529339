import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track3LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">HEAT DEATH (FEAT. MALIK ENGLISH)</p>
				<p id="lyrics-text">
					Push it to the bottom<br></br>
					Hand on my nine say goodbye now feel it bubble til it breach<br></br>
					Please tell me how to solve it<br></br>
					the sun in the sky wont abide now tell me can you feel the heat<br></br>
					Push it to the bottom<br></br>
					I been killing my pride one stem at a time until it bleed<br></br>
					Please tell me how to solve it<br></br>
					the sun in the sky wont abide now tell me can you feel the heat<br></br>
					<br></br>
					The wolves are pulling at the skin and I feel teeth on the chrome<br></br>
					But everything I ever got was always scraped from the bone
					The top is lonely but the bottoms dark and I cant feel rope<br></br>
					Everybody tryna grab and I cant break hold<br></br>
					Y’all gon fucking feel me whether you really like it or not<br></br>
					The blood is leaving out the chest and running right to the top<br></br>
					The sun is peeling back and tilting just to show you new god<br></br>
					the zombies crawling out the cracks to catch the pick of the crop<br></br>
					They used to think the moon was only made to walk on and stamp<br></br>
					And now they looking at the sky just to save us from man<br></br>
					Everything around me crumbling I cant figure the plan<br></br>
					The kids are burning and I only got some water for plants<br></br>
					Something in and out my dreams, I could never let a scream<br></br>
					Cause the motherfuckin ring would drown out all of the sound<br></br>
					Spinning out of puppet strings<br></br>
					I was never dreaming big<br></br>
					Higher than myself, I got this weight up on my shoulders<br></br>
					Put the gun in my waist, I’m looking back up at my city<br></br>
					The better romance the bands, I count em, looking pretty<br></br>
					My mind in the trance, I bet I dig it out the pity<br></br>
					Y’all gon feel me or not?<br></br>
					Y’all gon feel me or not?<br></br>
					<br></br>
					I know it’s kill or be killed<br></br>
					I know it’s kill or be killed<br></br>
					So I gotta ride with my steel, yeah<br></br>
					I gotta ride with my steel, yeah<br></br>
					I know it’s kill or be killed<br></br>
					I know it’s kill or be killed<br></br>
					So I gotta ride with my steel, yeah<br></br>
					I gotta ride with my steel, yeah<br></br>
					<br></br>
					Push it to the bottom<br></br>
					Hand on my nine say goodbye now feel it bubble til it breach<br></br>
					Please tell me how to solve it<br></br>
					the sun in the sky wont abide now tell me can you feel the heat<br></br>
					Push it to the bottom<br></br>
					I been killing my pride one stem at a time until it bleed<br></br>
					Please tell me how to solve it<br></br>
					the sun in the sky wont abide now tell me can you feel the heat<br></br>
					<br></br>
					I said welcome to heat death<br></br>
					Welcome to heat death<br></br>
					I said welcome to heat death<br></br>
					Welcome to heat death<br></br>
					If this is really the end, then I'mma need everybody to put their hands to the sky<br></br>
					If this is really the end, then I'mma need everybody to ride<br></br>
					<br></br>
					Smoke inside the precinct think the capsules might have got away <br></br>
					All my hope is tied to dirt im pulling out the flowers late<br></br>
					Look up in my eyes and see my soul get up and shadow walk<br></br>
					Dancing with the sirens feel the heat inside my body stalk<br></br>
					Is somebody watching over me could I just die today<br></br>
					Nickels in my bones I feel them pinching on my socket plate<br></br>
					Every bottom that ive seen is filled to brim with rattlesnakes<br></br>
					Please dont leave me in the cut I need some time to feel OK, alright!<br></br>
					Push it to the bottom<br></br>
					Hand on my nine say goodbye now feel it bubble til it breach<br></br>
					Please tell me how to solve it<br></br>
					the sun in the sky wont abide now tell me can you feel the heat<br></br>
					Push it to the bottom<br></br>
					I been killing my pride one stem at a time until it bleed<br></br>
					Please tell me how to solve it<br></br>
					the sun in the sky wont abide now tell me can you feel the heat<br></br>
					<br></br>
					I know it’s kill or be killed<br></br>
					I know it’s kill or be killed<br></br>
					So I gotta ride with my steel, yeah<br></br>
					I gotta ride with my steel, yeah<br></br>
					I know it’s kill or be killed<br></br>
					I know it’s kill or be killed<br></br>
					So I gotta ride with my steel, yeah<br></br>
					I gotta ride with my steel, yeah<br></br>
					<br></br>
					Mechanic: How much time left did you say you had on your super clock?<br></br>
					<br></br>
					P: About 10 days<br></br>
					<br></br>
					Mechanic: Ok, I can get this ready for delivery by then for sure. <br></br>
					Only thing is I’ll have to replace the inhibitor chip on the skin suit<br></br>
					but the casket should work and be getting enough protection from sweeper units<br></br>
					<br></br>
					P: And how much do I owe you for this?<br></br>
					<br></br>
					Mechanic: Oh nah I don’t charge for burial services<br></br>
					<br></br>
					*Exhaling smoke* Protag: Thank you. I appreciate it<br></br>
					<br></br>
					Mechanic: Nah it's not what you think. <br></br>
					Exchange of money pings the network and the extra cash isn’t really worth the federal suspicion<br></br>
					<br></br>
					P: I can always wash it and get it back to you?<br></br>
					<br></br>
					Mechanic: Nah I mean my life doesn’t revolve around this no more. <br></br>
					I got a family to think about now and risky business ain’t in my lane anymore.<br></br>
					Fortunately for you, I get subsidized by the bureau nowadays. NESG bill. <br></br>
					Anyways, go ahead and try it on
				</p>
			</main>
		);
	}
}

export default Track3LyricsView;