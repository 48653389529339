import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track7LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">LIMBO CONNECTION A+B (SKIT)</p>
				<p id="lyrics-text">
					If you haven’t yet already, please make yourself aware of the risks involved and health factors of this operation.<br></br>
					A digital manual is available at the bottom right.<br></br>
					<br></br>
					The cloning process is one of extreme risk. <br></br>
					The ongoing biosphere crisis and increased carbon dioxide in the air has <br></br>
					reduced the average lifespans of affected third generation individuals by half.<br></br>
					The goal of this process is to quickly repair the damaged organs, <br></br>
					de-link your body from your central unit before total degradation, and completely<br></br>
					re-print your anatomy from the machine code in your unit<br></br>
					<br></br>
					Please be aware that Everest and all Everestware companies are not liable for damages<br></br>
					resulting from manual service interruption, and the user is responsible for all personal security and privacy precautions.<br></br>
					<br></br>
					Casket cloning is outlawed in every sector of the **** galaxy.<br></br>
					Your chamber must be buried underneath the wired soil network in order to avoid pings on the system,<br></br>
					and both your body and chamber must be cloaked from sweeper unit satellites.<br></br>
					Any detection will lead to subsequent police intervention. Cybernetics and extra equipment may be necessary in order to ensure this.<br></br>
					<br></br>
					Now let’s begin.<br></br>
					<br></br>
					At this stage your skin suit should be applied; is it fitted over your body? Yes or no?<br></br>
					<br></br>
					Good. We will start to extract the somatic cells and begin some of the biological processes<br></br>
					necessary to replicate the body into the right chamber<br></br>
					<br></br>
					Are you opting for lethal gas or injection? If the former, please select your turning key number.<br></br>
					<br></br>
					Be aware that during this process, your brain will enter electromagnetic stasis, or limbo. <br></br>
					Each user’s experience is unique. The waves in your brain will continue simultaneously in both your original<br></br>
					and the copy, but at different frequencies. <br></br>
					<br></br>
					Do not be alarmed upon re-animation, as the body will have to adjust. Given the extreme sealant process,<br></br>
					the chamber doors will need to be forcibly broken which either your second, or a digger unit should assist with. <br></br>
					<br></br>
					Please press begin to start the process… and best of luck.<br></br>
					<br></br>
					<br></br>
					Everest is proud to have served 3 generations of colonies for over 2000 years since<br></br>
					the Great Intervention. As pioneers of interstellar migration, our legacy has lived on as we continue<br></br>
					to offer the best in clean energy solutions, shadow market wares, defense engines, and more, and we are pleased you have chosen us today.
				</p>
			</main>
		);
	}
}

export default Track7LyricsView;