import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track15LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">DON’T KILL MY LIGH_T</p>
				<p id="lyrics-text">
					Tell me can you live forever<br></br>
					Tell me can i live forever<br></br>
					Tell me can you live forever<br></br>
					Got some peace in my sight<br></br>
					Tell me can you live forever<br></br>
					Tell me can i live forever<br></br>
					Tell me can you live forever<br></br>
					Its not a dream if im right<br></br>
					Cuts in my eyelids was blinded by the glow<br></br>
					Stuck in the sky lit the sun that I provoke<br></br>
					<br></br>
					Don’t kill my light<br></br>
					Don’t kill my light<br></br>
					Don’t kill my love<br></br>
					Don’t kill my fight<br></br>
					Don’t kill my light<br></br>
					Don’t kill my light<br></br>
					Don’t kill my fire<br></br>
					Don’t kill my spite<br></br>
					Don’t kill my light<br></br>
					Don’t kill my light<br></br>
					Don’t kill my love<br></br>
					You can try if you’d like<br></br>
					<br></br>
					My dreams on fire, im pushing some water out of my throat<br></br>
					My Poseidon riding, i defy him cuz I dont fear the unknown<br></br>
					It’s good to seek some light, Im stripping fireflies to their bones<br></br>
					It’s getting darker n darker outside don’t block out my glow<br></br>
					You cant cattle my caliber I keep count of my clips<br></br>
					You cant battle my patterns of self destruction im lit  <br></br>
					Now tell me is it fate or fire that I just chose this religion<br></br>
					I built my churches on kismet and this dior that i christened <br></br>
					these are not the little weapons that you think are precision <br></br>
					I rain down heaven and hell baby my judgment is vicious<br></br>
					Clap my hands to the sky and god just rained down more pigeons <br></br>
					Does he want me to fly or is it a reminder of my position <br></br>
					Ok see now they will never minimize my light<br></br>
					My fire is forever yeah this shit burn too bright<br></br>
					My pulse is mad high when I reanimate from my stasis<br></br>
					Climb back up from my hell the fall down wasnt so gracious <br></br>
					The light is so blinding no you cannot believe it’s oasis<br></br>
					See my passion burn too hot for you to fuckin embrace it<br></br>
					<br></br>
					Tell me can you live forever<br></br>
					Tell me can i live forever<br></br>
					Tell me can you live forever<br></br>
					Got some peace in my sight<br></br>
					Tell me can you live forever<br></br>
					Tell me can i live forever<br></br>
					Tell me can you live forever<br></br>
					Its not a dream if im right<br></br>
					Cuts in my eyelids was blinded by the glow<br></br>
					Stuck in the sky lit the sun that I provoke<br></br>
					<br></br>
					Don’t kill my light<br></br>
					Don’t kill my light<br></br>
					Don’t kill my love<br></br>
					Don’t kill my fight<br></br>
					Don’t kill my light<br></br>
					Don’t kill my light<br></br>
					Don’t kill my fire<br></br>
					Don’t kill my spite<br></br>
					Don’t kill my light<br></br>
					Don’t kill my light<br></br>
					Don’t kill my love<br></br>
					You can try if you’d like<br></br>
					<br></br>
					Stuck in the past I feel a blast of adrenaline<br></br>
					Look at my wrath I never wanna show you sentiment<br></br>
					I flooded my mass Im feeling bad I need some medicine<br></br>
					Hand on my nine I bet I black i never can admit<br></br>
					peeling my skin the shit been burning off, its sediment<br></br>
					Never can die my heat survive in my skeleton<br></br>
					got venom in mind so surprise I let it leak<br></br>
					Im cutting my pies yeah I serve em to the fiends<br></br>
					Yeah I’m Jumping from the roof I got truth in my bones<br></br>
					My body a vessel that I let loose from my soul<br></br>
					Killing myself so I reside in my next life<br></br>
					Im a soldier of sandman my dreams will never bleed<br></br>
					Look in my eyes and see the devil repeat<br></br>
					And every worry in my heart that I never delete<br></br>
					I put the pain to the side got some rain on my mind and my fate on the line, are you acquainted with beast?<br></br>
					Now tell me who gon save us if it wont be us<br></br>
					And tell me who the fuck will hurt us if it wont be us<br></br>
					And tell me who gon burn us if it wont be the man with the sun in his hand now baby cant you see the light I release
				</p>
			</main>
		);
	}
}

export default Track15LyricsView;