import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track6LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">STRINGER BELL THEORY / CLONE CLONE CLONE</p>
				<p id="lyrics-text">Good luck you tin can ten penny good for nothing soldier <br></br>
					standing on the back of horse looking big and ugly<br></br>
					motherfuckers tryna touch me motherfuckers wanna dust me<br></br>
					motherfuckers wanna look inside my soul and pull me out<br></br>
					Good luck you tin can ten penny good for nothing soldier <br></br>
					standing on the back of horse looking big and ugly<br></br>
					motherfuckers tryna touch me motherfuckers wanna dust me<br></br>
					motherfuckers wanna look inside my soul and pull me out<br></br>
					I swear I feel like-<br></br>
					<br></br>
					All the ways I make you see<br></br>
					(Are you nervous now) Stringer Bell Theory<br></br>
					(Are you swerving now) Tell me who the hell have I been chasing this whole damn time<br></br>
					(Are you everything that you said you wanted to be)<br></br>
					<br></br>
					Youre much stronger than you think<br></br>
					Stupid motherfucker<br></br>
					Growing seeds out of your skin you’ll miss if you blink<br></br>
					Youre much stronger than you think<br></br>
					Look at the way that you crawled back from the brink<br></br>
					Of forever never<br></br>
					<br></br>
					Tough pills to swallow that I keep mashed in the back of my throat<br></br>
					How I look how I talk how I matter to you thats self centered I know<br></br>
					Who am I without this music, a plastic sheet cut out of ghost?<br></br>
					Is it my purpose or my burden or just a phase in my life oh no<br></br>
					I dont need friends<br></br>
					I dont need drugs<br></br>
					I dont need enemies<br></br>
					No no no no but I do need love<br></br>
					I dont need any of that bullshit youre telling me that I should do today<br></br>
					Swallowed a thousand light bulbs so you can see me glow through the fray<br></br>
					Im not as hood as I think<br></br>
					Im not as good as I think<br></br>
					Im not too solid with my feelings but i cant stop or ill sink<br></br>
					I keep feeling like just myself is maybe not good enough<br></br>
					Or is it actually and id just rather not be rouge enough<br></br>
					<br></br>
					Stuck to my bones<br></br>
					Every feeling that I ever set in my head<br></br>
					Mud in my chrome<br></br>
					Look at me and see the helium that I never let escape<br></br>
					God am I wrong<br></br>
					Blood dont try to tell me I can’t break the hold<br></br>
					Dont tell me im alone<br></br>
					I been fighting with you by my side<br></br>
					<br></br>
					Breaking back into my cage<br></br>
					Renew my karma, bluer dollars, through solace, headfirst trauma falling regardless<br></br>
					Man I cant tell anymore if it feels like growth spurt or tumors<br></br>
					Am I newer now or doubled down, yeah my aches they feel fewer<br></br>
					They carbon copies<br></br>
					The formula got sloppy, I cant fall in line w yall<br></br>
					these starter parts wont fit on my lips<br></br>
					See when they come, you never let the words fall out of your grip<br></br>
					So ive been saving all the rain drops my umbrella clipped<br></br>
					Im losing my trust, yeah I been losing my faith<br></br>
					Yeah ive been losing my confidence i feel my bones when they brace<br></br>
					Don’t take the high road for granted<br></br>
					Don’t take my patience for granted <br></br>
					They take my friendship for granted<br></br>
					They take my passion for granted<br></br>
					They take my loyalty for granted, yeah this is all of my panic <br></br>
					That ill just end up in dirt way too broken bitter and bandaged<br></br>
					Was I meant to be an artist, entertainer I don’t know<br></br>
					But something needs to give I cannot keep chasing this rope<br></br>
					They wanna box me in<br></br>
					They wanna downplay my work<br></br>
					Im more than what you see of me now tell me what am I worth<br></br>
					Is it streams likes loves follows engagement or press<br></br>
					Is it content money promo labels i dont want to guess<br></br>
					See I give my life to it all<br></br>
					I put my life in these songs<br></br>
					I run my right to abuse me<br></br>
					I can alright all my wrongs<br></br>
					Im a complicated person<br></br>
					way much more than you know<br></br>
					From staten island, border Hylan, where I made all my bones<br></br>
					Dont tell me who the fuck who I am am<br></br>
					Ill let you know the second that im good with that on my own<br></br>
					Dont tell me what the fuck I I am<br></br>
					What you think of me is everything im not anymore <br></br>
					<br></br>
					Technician: And you really have nothing left you want to say or do?<br></br>
					P: I do.. but what makes you ask that?<br></br>
					<br></br>
					Technician: Your soul needs to enter the grave first before your body does. <br></br>
					That’s what I always tell my clients<br></br>
					And I can tell youre looking for radiance<br></br>
					Sometimes all we need is a bit of light <br></br>
					<br></br>
					P: What do you mean?<br></br>
					<br></br>
					Technician: Let some light peek in through your casket… you’ll see what I mean. Even a little will be enough<br></br>
					<br></br>
					*activation*<br></br>
					<br></br>
					Technician: So, your chip is in. You’ll be able to print digger units at the site<br></br>
					and the alloy will keep your body undetected from sweepers. That’s gonna be the same price we discussed<br></br>
					<br></br>
					Technician: And here’s your receipt<br></br>
					<br></br>
					Technician: Thank you for using Secure Repairs and Transit<br></br>
					Best of luck in your travels<br></br>
				</p>
			</main>
		);
	}
}

export default Track6LyricsView;