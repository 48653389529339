import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track12LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
<p id="lyrics-text">CONNECTION B (SKIT)</p>
<p id="lyrics-text">HUHHHHHH!!</p>
			</main>
		);
	}
}

export default Track12LyricsView;