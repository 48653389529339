import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track14LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">ENOUGH</p>
				<p id="lyrics-text">
					They gon tell you that what you do is never enough<br></br>
					But you can put 2 and 2 together it aint so tough<br></br>
					Please dont worry about me yes I can call out a bluff<br></br>
					Try as they will Ill never give a single fuck<br></br>
					They gon tell you that what you do is never enough<br></br>
					But you can put 2 and 2 together it aint so tough<br></br>
					Please dont worry about me I-i can call out a bluff<br></br>
					Try as they will Ill never give a single fuck<br></br>
					<br></br>
					How much are your dreams worth?<br></br>
					What would you do for the things that you want <br></br>
					Would you bend a few morals or stick to your guns and if so, for how long?<br></br>
					How much is your skin worth<br></br>
					The cost of selling it off?<br></br>
					Play up your identity for someone else sympathy I cannot auction my cause<br></br>
					Is that your heart coming down, quick now is the feeling wearing off?<br></br>
					One pill is not enough you need another one to make your dopamine cough<br></br>
					How much are your hands worth?<br></br>
					Is it what they pay you to count?<br></br>
					Is it a house, a car, some jewelry, a loan for just 10% down<br></br>
					What about your happiness now, whats the price you put on that pound?<br></br>
					Is it for the love or the buzz or the trust or some hands to put on your crown<br></br>
					What do I have to share to let you know that I trust you enough with this house<br></br>
					What do I have to do make you love me, please can you show me right now<br></br>
					How much is your god worth?<br></br>
					What do I need to do to earn his heaven<br></br>
					Is it not enough that I try my best even with shaky intentions<br></br>
					Is it religion or pigeonholing myself if I just try to stick to your creed<br></br>
					Do I say I love you enough <br></br>
					or do I trust you enough to know what I mean<br></br>
					Tell me if my words cut deep<br></br>
					Did I say you enough for you to feel my spite<br></br>
					Am I being honest with myself in this moment<br></br>
					or do I just wanna be right<br></br>
					Is my ego protected enough from the silence I hear every time that I shout<br></br>
					Look at the way that youre telling me all the feelings that I ignore and I doubt<br></br>
					Everybody wants something from you <br></br>
					So what do you do when you feel youre not enough<br></br>
					Am I funny enough am I charming enough am I smarter enough am I creative enough <br></br>
					Am I bullshit enough<br></br>
					Was my punishment enough<br></br>
					For all of the mistakes that I make<br></br>
					Now tell me is my stomach too small to carry these puddles of water, I'll wait<br></br>
					<br></br>
					They gon tell you that what you do is never enough <br></br>
					But you can put 2 and 2 together it aint so tough<br></br>
					Please dont worry about me yes I can call out a bluff<br></br>
					Try as they will Ill never give a single fuck<br></br>
					They gon tell you that what you do is never enough <br></br>
					But you can put 2 and 2 together it aint so tough<br></br>
					Please dont worry about me I-i can call out a bluff<br></br>
					Try as they will Ill never give a single fuck<br></br>
					<br></br>
					Tell me what else do I gotta say
				</p>
			</main>
		);
	}
}

export default Track14LyricsView;