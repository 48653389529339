import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track8LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">LIFE_IN_A_TAXI_CAB</p>
				<p id="lyrics-text">
					I hail you down<br></br>
					Life in a taxi cab<br></br>
					I send the route<br></br>
					Life in a taxi cab<br></br>
					I look around<br></br>
					Life in a taxi cab<br></br>
					Please gun it now<br></br>
					Life in a taxi cab<br></br>
					<br></br>
					Said these days<br></br>
					Im bored of the flies<br></br>
					Im bored of the flies<br></br>
					Im bored of the<br></br>
					said these days<br></br>
					Im torn up inside<br></br>
					Got nowhere to hide<br></br>
					Im bored of the-<br></br>
					<br></br>
					Mind reader mind reader don’t come near me<br></br>
					Hand drawn quarter notes these are just template for my teeth<br></br>
					what is your idea of happiness I said mine is in the reach<br></br>
					I won’t can’t never will die living just at center speed<br></br>
					Happiest when I can see you, happiest when I can be me<br></br>
					Happy when im letting go of all the parts of myself that I can’t please<br></br>
					happy when I treat you better than I can treat myself<br></br>
					Self abuser manipulator all my threats of or else<br></br>
					I’m happier now than before when I look at my own reflection<br></br>
					Partly made my wisdom from a 16 year old’s projection<br></br>
					But i look so much like every single cut of my family tree<br></br>
					It could be a clashing of identity or the culmination of me<br></br>
					My culture, heritage, upbringing, environment, all translations of my reach<br></br>
					These are the roots of my frustrations over feeling incomplete<br></br>
					Being someone that yesterday me could see, been breaching my dreams<br></br>
					Looking out my window ive been seeking some peace, sun leaking through trees<br></br>
					always stoking my doubts when I built my happiness on everyone else approval<br></br>
					This is what the moments amount when everyone else idea of you is crucial<br></br>
					Head say keep moving, it’s frugal to think these intrusions are useful<br></br>
					Been losing as usual to the doubts in my head screaming through toothfuls<br></br>
					heart protected, hand to heavens<br></br>
					Passion is stoked by hellfire, i make my weapons<br></br>
					Happy on more days now than I used to be<br></br>
					Happy cause my friends are alive, my money is right, my body is free<br></br>
					For now though im still way too selfish to help you<br></br>
					protruding out the belly button im overgrown for this space where I meet you <br></br>
					All the time every day, I spend my life sitting in a taxi cab<br></br>
					Running away from something, but im getting close to making the lap<br></br>
					<br></br>
					Everybody dont move just stand there so I can watch the<br></br>
					Blood running to my heart down from my lung<br></br>
					I can’t move my face, shit is numb<br></br>
					<br></br>
					Mind reader mind reader don’t come near me<br></br>
					Fire in the sky I can meet that orange in this sleep<br></br>
					don’t sell all the furniture that you put in your glass house<br></br>
					And dont let them strip you of your purpose, is this reemergence or drown<br></br>
					Look at you now, every prayer through palms you pursed received<br></br>
					Tell me how’s the wisdom of being able to retire at 33<br></br>
					The dreams you had predicated are manifested and complete<br></br>
					Cheeks filled with roses, i only have a mouthful of some bees<br></br>
					Everybody wonders what life is like in the dirt<br></br>
					I just want you to tell me what its like in Memphis Tennessee<br></br>
					Remember all our conversations about the places we would go<br></br>
					Dissolving all our worries living down at the hotel beach<br></br>
					I’m nothing that my father had wanted me to be<br></br>
					Funny enough I never listened to anything he said<br></br>
					If you get a chance to be a father don’t forget<br></br>
					Raise your children with the warmth that you could never reap<br></br>
					Selfishly id trade every inch of distance that was reduced between meeting myself in god<br></br>
					for every dollar that you make without me standing there to watch<br></br>
					those are the anchors on my feet that i might have sank to the bottom with<br></br>
					the weight of ambition would really cave a hole right through this coffin pit<br></br>
					You made it out of circumstances that I could never imagine<br></br>
					With the light in your heart that would only part through refraction<br></br>
					Im just watching my life play out all on a video screen<br></br>
					Seeing again those I never said goodbye to, does this really mean<br></br>
					You can find love inside these memories, well its better than sleep<br></br>
					Might find peace in rememberance, all them peoples u reached<br></br>
					I found my religion while staring at the crossing in the street<br></br>
					Watching all the traffic, every single car they part for me<br></br>
					Head underwater my nose is really split at the sections<br></br>
					This is not descension, this is ascension, no<br></br>
					you and I can both be happy, Im happy right where im at, this view is amazing<br></br>
					All the time every day, I spend my life waiting in a taxi cab
				</p>
			</main>
		);
	}
}

export default Track8LyricsView;