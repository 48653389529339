import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track13LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">DON’T TELL THE KID_S</p>
				<p id="lyrics-text">
				Don’t tell the kids don’t tell the kids they know<br></br>
				I been running out of skies on this side of rainbow<br></br>
				Don’t tell the kids don’t tell the kids they don’t<br></br>
				See the boogeyman is crawling underneath the table<br></br>
				Don’t tell the kids don’t tell the kids I cope<br></br>
				I dont know if I can hide, even if I tried<br></br>
				Don’t tell the kids don’t tell the kids I hope<br></br>
				All these birds in the sky can you see em fly<br></br>
				<br></br>
				Everything is falling apart can I make it alright for just a second<br></br>
				I pray for nothing formed against me, that's worship or weapo<br></br>
				Now look in my own eyes and see im hiding all my stains<br></br>
				I been standing with needles in my umbrella through the rain<br></br>
				Nickel plated all my dreams but I can never be anything else<br></br>
				But a body do we turn to zombies after the smell<br></br>
				Tell me what you wanna be<br></br>
				Do we all go to heaven if the shooter leave us a second just to pray for reprieve<br></br>
				<br></br>
				Tell me what you want from me<br></br>
				Tell me what you want from me<br></br>
				I'm telling you I'm OK<br></br>
				Tell me what you want from me<br></br>
				Tell me what you want from me<br></br>
				Won't catch me running today<br></br>
				<br></br>
				(the money the cars the clothes everything that I have to give)<br></br>
				(please don't take my light please don't take my heart don't kill my hope and don't burn it all down)<br></br>
				<br></br>
				Don’t tell the kids don’t tell the kids Don’t tell the kids don’t tell the kids they don't<br></br>
				Don’t tell the kids don’t tell the kids Don’t tell the kids don’t tell the kids don't<br></br>
				Don’t tell the kids don’t tell the kids Don’t tell the kids don’t tell the kids they don't<br></br>
				See the boogeyman is crawling underneath the table<br></br>
				Don’t tell the kids don’t tell the kids Don’t tell the kids don’t tell the kids they don't<br></br>
				see the birds are falling out the sky<br></br>
				<br></br>
				Dont want my sun to grow up like me<br></br>
				Don't want my daughter to know my failures as a man<br></br>
				My children will look just like me, want to be just like me<br></br>
				But do I love myself enough to give them half of who I am<br></br>
				Every worry every habit my obsessions I feed<br></br>
				And every rabbit on my arrow that I killed just to eat <br></br>
				See they always tried to make me feel like less than what I was<br></br>
				So the lens I view myself is the same one that I use to judge, look<br></br>
				Sending my apologies in advance<br></br>
				For everyday that I dont live up to my expectations, please understand<br></br>
				For every hurt in my heart that I aint shed from my psyche<br></br>
				For each and every penny worth of pride I hold on to tightly<br></br>
				For every pressure I put on both them and on myself<br></br>
				Men of the house plant feet when the roof caves on itself<br></br>
				I been learning how to own up to all the shit that I’ve dealt<br></br>
				May I learn to give you everything I cant give myself<br></br>
				<br></br>
				They gon have to kill me, I seen the fires<br></br>
				The smoke is filling up my chest, my mouth is black lipped and dry<br></br>
				The sky is falling earth is shaking everybody get back <br></br>
				Everything is gonna be alright I buried the cat <br></br>
				Dont tell the kids dont tell the kids they don’t<br></br>
				See the boogeyman is crawling underneath the table<br></br>
				Dont tell the kids dont tell the kids they know<br></br>
				I been trying my best I promise ill be fine today though<br></br>
				<br></br>
				Dont tell the kids dont tell the kids they don’t<br></br>
				Need to know im running out of fingers when I swear though<br></br>
				Dont tell the kids dont tell the kids they know<br></br>
				All these sunken skies dont carry clouds im catching rain though<br></br>
				<br></br>
				Look in my own eyes and see my phantoms as a child<br></br>
				All my umbrellas had needles, now do I fish or do I drown<br></br>
				My love for music and rapping, it kept my head above the clouds <br></br>
				but I was anchored to the ground, yeah I swear that that shit had me bound<br></br>
				Thought I was weak cause I didn’t know how to fight<br></br>
				Overcompensated my reaction to everything was to spite<br></br>
				All my life I swear they always tried to kill my confidence<br></br>
				But ive been punishing myself way more than them, this is my audience<br></br>
				Look in my own eyes and see im growing into a man<br></br>
				Thought I was weak cause they looked at me and couldn’t understand<br></br>
				these are the parts that i only show if you pry through the hands<br></br>
				i play the martyr, jumping on grenades, bleeding heart that i am<br></br>
				i cant keep letting my ego get the best of me<br></br>
				i cant keep telling myself that i aint got the recipe<br></br>
				i cant lead anyone else if i cant lead myself<br></br>
				so this is every other past version of me that i help<br></br>
				Look in my own eyes and see the person that ive found<br></br>
				But are you disappointed with who you see or are you still proud<br></br>
				Should I think that im weak cause I got some money now<br></br>
				do I take care of my friends and family the way i think would count<br></br>
				Please dont block my sunshine, you see the pain that’s from my growth<br></br>
				Everybody is watching I show you all of my hope<br></br>
				Ive been swallowing knives just look inside of my throat<br></br>
				Been running from something, will I live up to all of my ghosts<br></br>
				</p>
			</main>
		);
	}
}

export default Track13LyricsView;