import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track1LyricsView extends Component {
    render() {
        return (
            <main class="trackLyricsView">
                <div>
                    <p id="lyrics-text">CONNECTION A (INTRO)</p>
                    <p id="lyrics-text">Seller: Look they sell these for cheaper in God City and you can probably find
                        <br></br>a lot of knockoffs that'll do the job. But given your situation, I think you’d want a really<br></br>
                        stable asphyxiant, AND high quality components for the chamber. Now you can put together a home-kit for free <br></br>
                        but as I understand it, you have a time problem and not a budget problem<br></br>
                        <br></br>
                        P: Yeah…. alright, show me how it works<br></br>
                        <br></br>
                        Seller: Cool. So this is the instructional video that comes along with the purchase. Now at a very basic level, <br></br>
                        you’re gonna pump the tank and stop once the indicator hits your turning number. Then once the clicks stop, <br></br>
                        you release the valve and the gas will pressurize in the container. Now after their patrol ends, I’d suggest you <br></br>
                        go home, get familiar with the device, and also the nuances of this machine because it takes a lot of tuning and <br></br>
                        patchwork from your second hand to make it work correctly
                        <br></br>
                        P: How does it decompose in the casket?<br></br>
                        <br></br>
                        Seller: it doesn’t. That’s the key. You want to keep this thing running in the casket with you until it breaks down, <br></br>
                        because typical sweepers have an easier time detecting CO2 in their half life. That’s how a lot of youse get caught nowadays<br></br>
                        <br></br>
                        P: Alright… that makes sense<br></br>
                        <br></br>
                        P: So how much is it gonna cost?
                    </p>
                </div>
            </main>
        );
    }
}

export default Track1LyricsView;