import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track16LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">FOLLOW THE LIGHT, YOU'LL BE ALRIGHT [BONUS]</p>
				<p id="lyrics-text">
					pitch it to black fuh <br></br>
					when the stick get attached <br></br>
					stick to the math fuck wait<br></br>
					Yeah I been counting it back<br></br>
					get it in blood always <br></br>
					Tell me is the pain always bad<br></br>
					I’m alone on my path and I can’t see the flash <br></br>
					Said i cant see you<br></br>
					<br></br>
					I dont wanna rush
					stitch inside the water in the cut<br></br>
					got a lot of pressure i combust<br></br>
					Finding my resolve from the mud<br></br>
					Crawling out the dirt into the flood<br></br>
					Dissolving all my grief into the cracks<br></br>
					Stuck in my position I react<br></br>
					Bomb inside my chest that I packed<br></br>
					I deal with it all on my own and that’s the only way I ever knew the path <br></br>
					<br></br>
					Don't need no one <br></br>
					can't give a fuck <br></br>
					Fuck all dat other shit i got peace worth enough<br></br>
					handle the rush<br></br>
					Money to the flush<br></br>
					cant move in my sleep but they yellin wake up <br></br>
					let go<br></br>
					<br></br>
					Too late<br></br>
					Too late, they flew way <br></br>
					Said its too late for me is it new day<br></br>
					New faith<br></br>
					Too late<br></br>
					Step back, its too late<br></br>
					Too late<br></br>
					Too late, step back its too late <br></br>
					<br></br>
					Y’all dont know shit about me, I been trying to reach out and all I feel are fingertips<br></br>
					Pls get the taste out of my teeth, I swallowed my pride and I drowned in that shit<br></br>
					Telling u the sun don’t come cheap , I got some green friends and all their faces counterfeit <br></br>
					These flowers won’t grow on me, got a mouth full of worms can you pull em out the pit<br></br>
					<br></br>
					Alone in the mothafuckin dark, I said<br></br>
					Feel for the light can you meet me by the edge<br></br>
					Running from the bite shit alright can I rest<br></br>
					Got a lot to say can I save it for tomorrow<br></br>
					Alone in the mothafuckin dark, I said<br></br>
					Feel for the light can you meet me on the edge<br></br>
					Running from the bite shit alright can I rest<br></br>
					Im tired of being angry, and im tired of feeling stuck<br></br>
					<br></br>
					28 billion of these motherfuckers under the sun<br></br>
					Im begging y’all to hear me even just one<br></br>
					Reaching out for surface finding more air for the lungs, grab some until the bones will dry out<br></br>
					Stuck in the bottom of this shit, I can’t fidget<br></br>
					Mutilated the wings to fly with the pigeons<br></br>
					Watch ambition retract from sky’s worth to inches<br></br>
					Got a lot of dreams and im not sure I can miss it<br></br>
					Divided my sight to keep my eye on a pivot<br></br>
					feelings i detonate, I make mines from the widgets<br></br>
					Sometimes it feel like I been desperate for a visit<br></br>
					God am I right, I need a sign and a picket, allah<br></br>
					Riding in your city cruising down in the midst<br></br>
					Bet that I remain, even if the nightmares will eclipse<br></br>
					Provided I’m alive, imma strive for the chips<br></br>
					Swollen in my chest I’m sick of screaming from the pit<br></br>
					Watching all these suckers who I’m better than exist <br></br>
					Megatron arrived, you can feel the motion in the clicks<br></br>
					Swim into the bite, save the blind from the fish<br></br>
					Am I alone or losing light, can’t see you on my hip<br></br>
					<br></br>
					Ho shut the fuck up<br></br>
					Bow your head<br></br>
					It’s hard to play tough<br></br>
					Inside my head<br></br>
					Im finding dead bugs<br></br>
					Please back up<br></br>
					<br></br>
					New days of rain, got clouds for sale<br></br>
					Standing under water mouth open to catch the nails<br></br>
					Take needles and pins<br></br>
					Keep the mask tight, get money stack right, fore they <br></br>
					blow the bagpipes and bury you in<br></br>
					Do I love it or do I hate it, shit my answer depends<br></br>
					Am I staring at the finish line or facing the end<br></br>
					Count up 7 of my fans and then all 5 of my friends<br></br>
					Been building up my family for some blood worth a swim<br></br>
					Swinging nooses from the knots, do I dangle or do I hinge?<br></br>
					See the mud, am I just enough to matter to them? and-<br></br>
					La la la la la<br></br>
					Motherfuck what you saying they don’t deserve what I been <br></br>
					My self esteem in the mud and I can’t drag above knees<br></br>
					I play pretend to myself, how could they love me for me<br></br>
					Strip the skin of cuts, flush the blood, press down on the bruise<br></br>
					Til it turn to white, im feeling light, tip needles to drain<br></br>
					What am I hiding like, im plenty sane, clip voltage to brain<br></br>
					Is everything alright, I heard you muffle the pain<br></br>
					Gripping jaws inside the pliers just to settle novacain<br></br>
					I think that I really earned my smile today<br></br>
					Am I defective or just missing all the parts that are sold separate<br></br>
					what do I even want, blow genie wishes to guesses<br></br>
					Bleeding from the heart, gotta pressure the gauze<br></br>
					See the stars, moon and mars, weigh the odds, am I doing thisshit wrong
				</p>
			</main>
		);
	}
}

export default Track16LyricsView;