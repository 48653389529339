import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track9LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">IT’S DARK + HELL IS HOT (FEAT. DANTE GENESIS)</p>
				<p id="lyrics-text">
					Stuck in the matrix I gotta find a fuckin way out<br></br>
					Blood in my tissue I had to cut my throat on way down<br></br>
					fuck it im vicious I gotta bite lip on the pain now <br></br>
					stress on my shoulders yeah that shit weigh like fuckin eight pounds<br></br>
					Stuck in the matrix I gotta find a fuckin way out<br></br>
					Blood in my tissue I had to cut my throat on way down<br></br>
					fuck it im vicious I gotta bite lip on the pain now <br></br>
					yall got be kidding I bet you really try to<br></br>
					<br></br>
					God in residual could never pray for my demise<br></br>
					blood in my visual I had to scrape it from the eyes<br></br>
					I been official, got plenty bones up in my ties<br></br>
					money gorillas, I bet you try to minimize<br></br>
					Step in my vision, come catch a glimpse and see the skies<br></br>
					i paint a picture, go figure I’m dragging on the pulse<br></br>
					Been standing in the deep end watch me how I get it close<br></br>
					I wasn’t drowning, I had to figure out the rope<br></br>
					Everything I got is for life<br></br>
					nothing missing better pay me right<br></br>
					I can’t show my face not this time<br></br>
					I been trapped and thats it, uh<br></br>
					<br></br>
					Stuck in the matrix I gotta find a fuckin way out<br></br>
					Blood in my tissue I had to cut my throat on way down<br></br>
					fuck it im vicious I gotta bite lip on the pain now<br></br>
					stress on my shoulders yeah that shit weigh like fuckin eight pounds<br></br>
					<br></br>
					All of my dreams, my fears, my pride, my goals, my sacrifices<br></br>
					Angel on my shoulder got the devil on the right hip<br></br>
					Nothing by design I had to huddle up the right chips<br></br>
					Asking why am I alive and god replied its righteous<br></br>
					wear it on my sleeve so how the fuck can you really play me now<br></br>
					Been feeling something missing, I cant figure out the finger count<br></br>
					Is it money magic god or skill could never really tell you how<br></br>
					Hell is freezing over water and I been step beneath the ground<br></br>
					And I been losing my grip on this shit I feel it slippin me<br></br>
					its hot in my face and I feel it melt beneath the skin I bleed<br></br>
					I cannot show it no weakness in my mental, get me please<br></br>
					drumming on the casket ’til my knuckles find a hole at least<br></br>
					<br></br>
					blood it tit for tat, I bet I get this shit forever<br></br>
					Put it on my rep I hope they really try to get up<br></br>
					enough of chitter chat, I swear I know you see me fed up<br></br>
					Patterns on my chest I had to rip the veins from head up<br></br>
					Stunting ay ay, I gotta dead it fore they pedal<br></br>
					rocks in my teeth, I got it icy on the threshold<br></br>
					god in my grips, you know my aura feeling special<br></br>
					stuck in the beast, I gotta stay my head on level<br></br>
					put my foot on your neck, you gon have to find a way out<br></br>
					Put the red dot on your head I figured that you pray now<br></br>
					Something embarrassing, had to stumble on my way down<br></br>
					Cuts on my body shit got me fighting off the pain now<br></br>
					<br></br>
					blood for blood, money dance, gotta<br></br>
					Fuck it up, look look look<br></br>
					<br></br>
					I got plenty in the teeth feel the tight grip<br></br>
					Something inside is feeling wrong i see the sky rip<br></br>
					Everything I ever got I had to fight for it, shit<br></br>
					It’s hell on earth and I been opened up the fire pit<br></br>
					im out of options so better duck Ive been strapped and ready to blow<br></br>
					got the pressure in effect, the metal follow where I go<br></br>
					All I see are demons, I just had to re-adjust the scope<br></br>
					Money power and respect that’s all I want so give me what im owed<br></br>
					Rocks on my neck, chains on my chest, come feel the bones<br></br>
					Of course i done bled, I honor my rep, I never fold<br></br>
					im sick in the head, got drills in the mesh, straight bullet holes<br></br>
					This shit aint no threat, I leave em a mess, you better know<br></br>
					<br></br>
					Quick, run<br></br>
					Aint no fun when the rabbit hunts<br></br>
					Got a lot to say my throat feel stuck<br></br>
					the bottom of this hell is not enough <br></br>
					Motherfuck uh<br></br>
					<br></br>
					Built a bridge to get over bitch I'm hammerin<br></br>
					Pulled up in a bigger Benz like lewis hamilton<br></br>
					finger's dirty my bitch is purdy she look like Zoe Kravitz<br></br>
					shawty on my dick, back up off me i'm a dad bitch<br></br>
					Talkin this and that let me guess oh you a savage<br></br>
					If you seen the shit I seen you'd move in with your parents<br></br>
					<br></br>
					(Shut the fuck up lil nigga don't rush me)<br></br>
					Options runnin out, poison in the bank account<br></br>
					Skullys turn to ski mask, pay me what you want me now<br></br>
					I'll put a nigga lights out<br></br>
					If the crib be lights out<br></br>
					Put him on the freeway until he know what freeway talkin bout<br></br>
					<br></br>
					They gon have to kill me if they ever want me dead<br></br>
					Flooded my ships now I got fishes in my head<br></br>
					Kiss with a semi I feel the bullet tear my flesh<br></br>
					Baby im worried that I got nothing in me left<br></br>
					They gon have to kill me if they ever want me dead<br></br>
					Flooded my ships now I got fishes in my head<br></br>
					Look in my eyes and see the devil I reflect<br></br>
					But baby dont worry see I got plenty left in me left<br></br>
					<br></br>
					Ash for rain<br></br>
					Bad propane<br></br>
					blood water soaks through patchwork stains<br></br>
					God is brilliant<br></br>
					This darkness wanes<br></br>
					Come follow the light<br></br>
					And see our flames<br></br>
				</p>
			</main>
		);
	}
}

export default Track9LyricsView;