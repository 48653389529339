import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track10LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">THE GHOSTS AIN'T REAL (FEAT. RODNEY CHROME)</p>
				<p id="lyrics-text">
					Im backwards walking down my steps<br></br>
					My head is spinning ‘round my neck<br></br>
					I cast you out devil beast!<br></br>
					My father Holy Spirit said<br></br>
					Please get out of my head<br></br>
					No dont let me drown in my sorrow<br></br>
					<br></br>
					Don’t be afraid of the dark maybe the ghosts aint real<br></br>
					Put my hand over heart baby the ghosts ain’t real<br></br>
					I been shining my thoughts maybe the ghosts aint real<br></br>
					swear these walls they won’t talk Baby the ghosts ain’t real<br></br>
					<br></br>
					they gon have to kill me if they ever want me dead (can't kill me)<br></br>
					Flooded my ships now I got fishes in my head (I'm drowning)<br></br>
					Everybody keep still I got lead on my breath<br></br>
					They got me nailed to the bed Baby the ghosts ain't real!<br></br>
					<br></br>
					Stuck to the bottom of my throat the rope I throw down I choke it<br></br>
					To fish out everything I swallowed this my pride by the gold fish<br></br>
					These are all of my fears yeah you see my pocket of bones<br></br>
					Look inside my painted eyes, i turned my glasswork to stone<br></br>
					Would you still love me if I showed you everything that I am, yeah im worried<br></br>
					the closer that I move to you the more that im blurry<br></br>
					I know the ghosts ain’t real but I can feel all their hands on me<br></br>
					Please dont step in front the fire my seance just burn through randomly<br></br>
					what is it that i am afraid of, is it your judgment or mine<br></br>
					Or are you just as scared as me to peek inside of the blinds<br></br>
					Yes im missing my pennies, just bled it all in the fountain<br></br>
					cause I kept wishing you’d get it see I got shame shaped a mountain<br></br>
					Please dont kill my light I dont walk this time of the night without it<br></br>
					Is that sugar rain or rice coming down, i receive in thousands<br></br>
					Everything that I feel should I tell you or should I hide<br></br>
					Would that make me brave if I just kept it all inside<br></br>
					<br></br>
					Stuck to the money tree all the nickels I buried<br></br>
					What if I give everything and I get nothing im worried<br></br>
					ive been fighting off the bees I got stings on me hurry<br></br>
					Look inside of my sleeves and read these pages theyre blurry<br></br>
					Can you tell me if the ghosts are real my ouija jerky<br></br>
					I got message on read I know how it hurt<br></br>
					Never said it, dried the tears til they till, my soil crumbles under pressure<br></br>
					Do you see me for real i been draining most of myself<br></br>
					put everything I got just to make you love me back<br></br>
					Dont slap the flowers out my hand when I give them, I been saving that<br></br>
					All this shit is overwhelming I can’t tell what you think<br></br>
					Habits of loving my own self, yeah I been working the kinks, see the<br></br>
					Lies that I can’t fit, my body tired I tried to walk<br></br>
					Got some plots and my sewer socks this my jihad through the mud<br></br>
					See I’ve been pushing it deep, see I been trying to fight it off<br></br>
					Everything that I can’t control this is pride before the fall<br></br>
					This the feeling that I can’t get out of my head<br></br>
					every mistake that I made am I missing some more<br></br>
					But this the feeling that I shed out, pull at my flesh<br></br>
					I cannot be scared to show you everything I am<br></br>
					<br></br>
					Don’t be afraid of the dark maybe the ghosts aint real<br></br>
					Put my hand over heart baby the ghosts ain’t real<br></br>
					I been shining my thoughts maybe the ghosts aint real<br></br>
					swear these walls they won’t talk Baby the ghosts ain’t real<br></br>
					<br></br>
					I ain't scared no more<br></br>
					I been here before<br></br>
					Angels been spared my soul<br></br>
					Feeling invincible<br></br>
					Invincible<br></br>
					Invincible<br></br>
					Invincible<br></br>
					Invincible<br></br>
					Invincible<br></br>
					Invincible<br></br>
					Invincible
				</p>
			</main>
		);
	}
}

export default Track10LyricsView;