import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track5LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">THE FIRE TRUCKS ARE ON FIRE AND THE LIFE LINE SPOOLS LIKE HAYWIRE</p>
				<p id="lyrics-text">
					Stuck saying that I need<br></br>
					All your love that I seek<br></br>
					Do you mind if I keep<br></br>
					All this water for me, I said<br></br>
					Stuck saying that I need<br></br>
					All your love that I seek<br></br>
					Do you mind if I keep<br></br>
					All this water for me, I said<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					<br></br>
					Why can't I run?<br></br>
					<br></br>
					Stuck saying that I need<br></br>
					All your love that I seek<br></br>
					Do you mind if I keep<br></br>
					All this water for me, I said<br></br>
					Stuck saying that I need<br></br>
					All your love that I seek<br></br>
					Do you mind if I keep<br></br>
					All this water for me, I said<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					<br></br>
					Nothing in your faith could ever save you from the wrath<br></br>
					Shit I been counting up my dreams I sit em on the stack<br></br>
					Stuck to the quarter, a kilo count will get you back<br></br>
					Hear them fishing for metal out of your head, I never lack<br></br>
					Tell me can you see the fires in the sky<br></br>
					Know it get harder to breathe when your neck on the line<br></br>
					Stuck in your teeth is it prayer or is it pantomime<br></br>
					Its the pride of man to think someone will save us this time<br></br>
					Who the fuck will save us if it won’t be us, what what<br></br>
					Made in his image so how can we not be enough, uh<br></br>
					see the kettle poke out its head can you feel the weight<br></br>
					thats 5 years determinate minimum in New York State<br></br>
					Everything is burning, all on my neck and my chest<br></br>
					But they can never kill me no im too live for their death<br></br>
					Cuts in my teeth are they nickel plated, nevermind<br></br>
					ready for anything I been fighting all my life<br></br>
					Everything burning off the rip, I got plenty on my skin, <br></br>
					said they wanna see me in the mud<br></br>
					Look in my eyes, I got a burst worth a full clip, <br></br>
					I dont wanna pull it, but its not enough
					<br></br>
					Stuck saying that I need<br></br>
					All your love that I seek<br></br>
					Do you mind if I keep<br></br>
					All this water for me, I said<br></br>
					Stuck saying that I need<br></br>
					All your love that I seek<br></br>
					Do you mind if I keep<br></br>
					All this water for me, I said<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					Fire trucks are on fire<br></br>
					<br></br>
					Dig in the dirt feel the earth reimburse what you paid<br></br>
					Who you calling for the cape is it drugs money love or just a touch<br></br>
					Real life or the matrix<br></br>
					Everybody watch you from their spaceship<br></br>
					Who the fuck are you to save me you are not above<br></br>
					These days ive been feeling recluse<br></br>
					Feel like everybody wanna put me in a box 6 x 9 x 12 feet<br></br>
					Doubt in my head, sword in my heart, stress by the pound<br></br>
					This music is not your life line no it can’t save you<br></br>
					Looking around, where the fuck is my crowd<br></br>
					Im in it by myself again only I could take it all the way through<br></br>
					2pacalypse now, falling on the ground<br></br>
					Every dead bird they couldn’t staple back to the clouds, what<br></br>
					Respect is better than love where I come from<br></br>
					The trauma of not having control isn’t that fun<br></br>
					I pull it back and let it explode when I dump em<br></br>
					Yeah I want it all, every residual I pick up em<br></br>
					Shit is flooded, yeah I said its something moving in my heart, proof of life under mars<br></br>
					Every worry that I never let escape<br></br>
					Releasing it all, fuck the withdrawals<br></br>
					See how I manage all the damage that been sitting underneath the breast plate<br></br>
					All I see are fake revolts in the context of what they fought<br></br>
					Claiming its breaking walls but they just want part of it all<br></br>
					Now tell me who the fuck will save us if it won’t be us<br></br>
					And who the fuck will hurt us if it won’t be us<br></br>
					People die everyday so tell me how is it a mystery<br></br>
					Rising out of the grave every spirit I could bring with me<br></br>
					Rush me with a thousand cuts you could never get rid of me<br></br>
					I dont need a life line I just need you to come sit with me<br></br>
					<br></br>
					Shopkeeper: So tell me… what are you hoping to see on the other side?<br></br>
					<br></br>
					P: Well.. are you asking what I’m hoping to see or what I think I’ll see?<br></br>
					<br></br>
					Shopkeeper: How about both?<br></br>
					<br></br>
					P: Honestly, I always had the typical idea of afterlife in my head…<br></br>
					Heaven and hell type stuff. But I don’t know, I think maybe realistically <br></br>
					I just expect darkness. Nothing but brain matter
					<br></br>
					Shopkeeper: Yeah… see that’s belief pedigree<br></br>
					We got belief in the rain, belief in the sun<br></br>
					… that everything will be good if not today then tomorrow…<br></br>
					That’s belief we inherited<br></br>
					Belief in letting go.. even though everything eventually burns<br></br>
					You don’t believe in afterlife, yet you’re chasing sanctity..<br></br>
					<br></br>
					… anyways. remember, there’s a crop of badlands a few clicks out - <br></br>
					avoid the ash storms and the fire lights. No satellites past the East Point<br></br>
					so you’ll have to follow the compass for a couple miles<br></br>
					Once you get to Block City, you’ll wanna see my girl in Unit 55 Building V<br></br>
					she’ll get you on your way
					<br></br>
					Good luck
				</p>
			</main>
		);
	}
}

export default Track5LyricsView;