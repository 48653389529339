import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track2LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<div>
					<p id="lyrics-text">ANDROIDS DREAD THE REDS</p>
					<p id="lyrics-text">Is everything OK? they be asking like Id say<br></br>
						I been screaming back that I aint feeling love today<br></br>
						Trust me I been bad I got some pressure for the pain<br></br>
						put the flowers on the shovel when you send me to the grave<br></br>
						Is everything OK? they be asking like Id say<br></br>
						I been screaming back that I aint feeling love today<br></br>
						Trust me I aint mad I got some money I could make<br></br>
						I can’t feel nothing above my waist<br></br>
						<br></br>
						Don’t try to test me, don’t try to test me<br></br>
						I got a long list of worries in my head they’ll probably kill me today<br></br>
						Don’t try to catch me, dont try to catch me<br></br>
						im screaming out bloody murder feel like imma see the reaper today<br></br>
						I been sinning so you say, push the moths inside the flame<br></br>
						I gotta face my facts ill never see them brighter days<br></br>
						Don’t try to test me, don’t try to test me<br></br>
						I got a long list of worries in my head they’ll probably kill me today<br></br>
						<br></br>
						I said put your hands up to the sky<br></br>
						Can’t you tell that the sun might be coming out<br></br>
						This the shit that make me feel alive<br></br>
						Is it dirt or placenta that im spitting out<br></br>
						<br></br>
						Searching for happiness yeah I got some money on tomorrow <br></br>
						all of my dreams are falling got some sorrow, didn’t mean to borrow<br></br>
						The planet is burning down and the money don’t make me believe<br></br>
						The kids are asking for love and I can only give em some relief<br></br>
						<br></br>
						Stuck in the moment can you tell me if im living or not <br></br>
						they took the heart up out my chest just to make it a prop<br></br>
						Put the money in the bag and then I back it up now<br></br>
						Ask me if I give a fuck whether you make it or not<br></br>
						I was fighting for myself inside the belly of beast<br></br>
						trying to find someone to drag me out the mud, it sink deep<br></br>
						Am I still on track to be the man I was planning to be<br></br>
						fuck if you judge these are words that I’ve been catching in teeth<br></br>
						its a lot of them trying to make their shit thinking they real<br></br>
						But they really not, and I hate em for that<br></br>
						its a lot of them trying to make their shit thinking they real<br></br>
						and I hate it I got a long list of bad ideas<br></br>
						i was in the dirt looking for a reason to go!<br></br>
						said id rather die young, not tryna grow old!<br></br>
						999 problems yeah im fixing for more!<br></br>
						Said ill make it do or die lotta C4 to blow!<br></br>
						<br></br>
						Hey sir!<br></br>
						I said do you have any change!<br></br>
						<br></br>
						Uh naw I don’t I’m sorry<br></br>
						<br></br>
						Hm.. that’s alright<br></br>
						There’s something else you can help me with tho<br></br>
						See that pigeon over there? It took my food<br></br>
						What you say you get it back for me?<br></br>
						<br></br>
						Uh… I don't know what you mean<br></br>
						<br></br>
						Ah ok right… nothing more that you and I could do about it right?<br></br>
						Hm..<br></br>
						Have a blessed day then<br></br>
						<br></br>
						*Phone rings*<br></br>
						Yo come to the garage, it’s ready
					</p>
				</div>
			</main>
		);
	}
}

export default Track2LyricsView;