import React, { Component } from 'react'
import './TrackLyricsView.css';

class Track4LyricsView extends Component {
	render() {
		return (
			<main class="trackLyricsView">
				<p id="lyrics-text">718 HELL</p>
				<p id="lyrics-text">
					7 1 8 hell I been stuck in this pit too long<br></br>
					They might try to kill me but my soul will never be taken by them<br></br>
					I am so-ro-rry my umbrella got holes, im fishing for flies<br></br>
					Look at my face I see all these bodies are falling straight out the sky (please get out)<br></br>
					<br></br>
					It's heavy lead when I spill with it<br></br>
					I been chasing heaven looking down I’m dragging heel with it<br></br>
					Wishing i could scrap all my past but I heal with it<br></br>
					All I fuckin want in this life is a meal ticket<br></br>
					Falling from the drop pray to god that it feel wicked<br></br>
					Searching for some hop what you got, please get real with it<br></br>
					Trying to pull myself up from out the rot can you stop, hell is hot, <br></br>
					stand on top, feel the drop, I’m jack and Jill with it<br></br>
					Look into my eyes and see the knots<br></br>
					hand to crown, blah blah with the shots<br></br>
					Pressing down, turn my pride into props<br></br>
					Sugar stir water burn left brain to drops <br></br>
					I got a long list of worries I keep<br></br>
					Been stressed so I guess you might catch me off leash<br></br>
					Screws loose in my head who gon strap them to teeth<br></br>
					Im begging you rock it lets pop it and see<br></br>
					I been frothing OD<br></br>
					<br></br>
					7 1 8 hell I been stuck in this pit too long<br></br>
					They might try to kill me but my soul will never be taken by them<br></br>
					I am so-ro-rry my umbrella got holes, im fishing for flies<br></br>
					Look at my face I see all these bodies are falling straight out the sky (please get out)<br></br>
					<br></br>
					dont dont dont<br></br>
					Keep thinking that I am afraid<br></br>
					dont dont dont<br></br>
					Tell me how you know real from the fake<br></br>
					dont dont dont<br></br>
					Put all of my feelings aside<br></br>
					dont dont dont<br></br>
					I was raised in the teeth of canines<br></br>
					<br></br>
					7 1<br></br>
					8 9<br></br>
					2 9<br></br>
					3 4 7 call me Ill show you how to trap flies<br></br>
					you scared to check?<br></br>
					you aint gotta guess I got venom in the neck<br></br>
					7 1 8<br></br>
					9 2<br></br>
					9 1 1 tell me who you call to protect<br></br>
					Everybody wanna be up next so you bet<br></br>
					been growing some eyes on the back of my head<br></br>
					<br></br>
					Its funny how they want me in the morgue<br></br>
					Flushing out the worries dripping one each from the pores<br></br>
					Flinching from the touch I got some numbing for your sores<br></br>
					stop it little dummy do you really want some more<br></br>
					Clipping on the nerve, see the C4 in my chest<br></br>
					red green or blue which wire should I compress<br></br>
					Everybody watching all these eyes gon peel the flesh<br></br>
					Sick of feeling sick, im sipping saline through the neck<br></br>
					Rush to the head<br></br>
					Gotta flood out the lead<br></br>
					See me in the mud looking up, no reflection<br></br>
					I feel like its dark down here and I can’t get out<br></br>
					Untuck from the nest I can’t breathe poke my head out<br></br>
					Scruffs on my knees from belief, is my breath loud<br></br>
					Everythings black in my dreams, am I dead now<br></br>
					Hands on my body, tryna drag me to their neck now<br></br>
					Bleeding through the vest, I can’t scrub all the red out<br></br>
					<br></br>
					Catching cobwebs from stationary spiders<br></br>
					Maybe I should poke to see if they’re still alive?<br></br>
					using pliers to make my mouth wider<br></br>
					and sealing novacaine inside the mice<br></br>
					Sugar coated the blame til it attracted the lice<br></br>
					Nickel plated the brain now its skewing my bite<br></br>
					Needle threaded the veins to keep my lattice spun tight<br></br>
					Been stitching my mouth just to impress some people I don’t like<br></br>
					January dreamers on parachute strings<br></br>
					Stunning new billboards in district green<br></br>
					Hands inside the car no more pictures please<br></br>
					life in the fish tank, artificial monkeys hanging off my trees<br></br>
					Got a handful of some plastic from scratching off these patches on my knees<br></br>
					Stopping at the ankle to dangle like a V<br></br>
					Ripping out the circuits what’s the matter can you breathe?<br></br>
					<br></br>
					7 1 8 hell I been stuck in this pit too long<br></br>
					They might try to kill me but my soul will never be taken by them<br></br>
					I am so-ro-rry my umbrella got holes, im fishing for flies<br></br>
					Look at my face I see all these bodies are falling straight out the sky (please get out)<br></br>
					<br></br>
					dont dont dont<br></br>
					Keep thinking that I am afraid<br></br>
					dont dont dont<br></br>
					Tell me how you know real from the fake<br></br>
					dont dont dont<br></br>
					Put all of my feelings aside<br></br>
					dont dont dont<br></br>
					I was raised in the teeth of canines<br></br>
					<br></br>
					7 1<br></br>
					8 9<br></br>
					2 9<br></br>
					3 4 7 call me Ill show you how to trap flies<br></br>
					you scared to check?<br></br>
					you aint gotta guess I got venom in the neck<br></br>
					7 1 8<br></br>
					9 2<br></br>
					9 1 1 tell me who you call to protect<br></br>
					Everybody wanna be up next so you bet<br></br>
					been growing some eyes on the back of my head<br></br>
					<br></br>
					7 1<br></br>
					8 9<br></br>
					2 9<br></br>
					3 4 7 call me Ill show you how to trap flies<br></br>
					you scared to check?<br></br>
					you aint gotta guess I got venom in the neck<br></br>
					7 1 8<br></br>
					9 2<br></br>
					9 1 1 tell me who you call to protect<br></br>
					Everybody wanna be up next so you bet<br></br>
					been growing some eyes on the back of my head<br></br>
				</p>
			</main>
		);
	}
}

export default Track4LyricsView;